import React from "react"
import Lottie from "react-lottie"
import styled from "styled-components"

import GlobalFonts from "../font/fonts"

import Header from "../components/header"
import NavV3 from "../components/NavV3"
import NavV2 from "../components/NavV2"
import TestiCards from "../components/testicards"
import FAQ from "../components/FAQ"
import FooterContainer from "../components/FooterContainer"
import Signup from "../components/Signup"
import ExplanationVideo from "../components/ExplanationVideo"
import Stepbystep from "../components/Stepbystep"

import topbackground from "../assets/TopPolygon1.svg"
import topbackground2 from "../assets/TopPolygon2.svg"

import google from "../images/google-play-soon-2.png"

import phones from "../assets/Phones2.svg"
import shadowPhones from "../assets/shadowPhones.svg"
import SEO from "../components/seo"

const Android = () => {
  const [email, setEmail] = React.useState("")
  const [emailError, setEmailError] = React.useState("")
  const [emailSent, setEmailSent] = React.useState("")

  const handleSumit = event => {
    console.log("FUCK YOU")
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault()
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    console.log("FUCK YOU")

    if (re.test(email)) {
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.

      var axios = require("axios")
      var data = JSON.stringify({
        email: email,
        dataFields: { source: "web", android: "True" },
      })

      // frontend iterable
      var config = {
        method: "post",
        url: "https://api.iterable.com/api/users/update",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "dd373517425447ca8ab5d993282d17ab",
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setEmailSent("We'll send you info!")
        })
        .catch(function (error) {
          console.log(error)
        })

      console.log(email)
      setEmailError("")
    } else {
      // invalid email, maybe show an error to the user.
      console.log(email)
      setEmailSent("")
      setEmailError("Please set a valid email")
    }
  }

  return (
    <>
      <SEO
        title="Android App"
        keywords="Vacation home Real estate investing, is real estate investing worth it, is real estate investing a business,how to start real estate investing"
      />{" "}
      <NavV2 />
      <Body>
        <GlobalFonts />
        <Tbackground>
          <TopBackground src={topbackground} />
          <TopBackground1 src={topbackground} />
          <TopBackground2 src={topbackground2} />
        </Tbackground>
        <Hero>
          <Logo src={google} />

          <Hero2>
            <H1Text>Android Coming Soon</H1Text>
            <PText>
              Join Our Waitlist and Receive A Free Share For Waiting
            </PText>
            <CTADiv>
              <CTAForm
                style={{
                  flexDirection: "row",
                  display: "grid",
                  jusityitems: "center",
                  alignitems: "center",
                }}
                onSubmit={handleSumit}
              >
                <CTAInput
                  type="text"
                  placeholder="email"
                  onChange={event => setEmail(event.target.value)}
                ></CTAInput>

                <CTAButton type="submit">JOIN WAIT LIST</CTAButton>
              </CTAForm>
              <span style={{ color: "red" }}>{emailError}</span>
              <span style={{ color: "black" }}>{emailSent}</span>
            </CTADiv>
          </Hero2>
        </Hero>
        <FooterDiv>
          <FooterContainer />
        </FooterDiv>
      </Body>
    </>
  )
}

export default Android

const Body = styled.div`
  position: relative;
`

const Tbackground = styled.div`
  max-width: 100%;
  overflow-x: hidden;
`

const Hero = styled.div`
  max-width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

  margin: 10px auto;

  @media only screen and (max-width: 625px) and (min-width: 100px) {
    flex-direction: column-reverse;
    margin: -70px 50px 50px -20px;
  }
`

const Hero1 = styled.div`
  
  display: flex;
margin: 100px auto 0px 0px;
padding 100px auto auto 100px;
  justify-content: center;
  align-content: center;
  margin: auto;

  @media only screen and (max-width: 625px) and (min-width: 100px) {

    margin: 100px auto 50px 100px;

  }  

  
`

const Hero2 = styled.div`
  
  
  margin:  auto;
  padding 100px 0px 0px 100px;
    flex: 1;
  align-items:space-evenly;
  justify-content: center;
`

const CTADiv = styled.div``

const TopBackground = styled.img`
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -4.95%;
  bottom: 78.14%;
  z-index: -10;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    width: 1712.46px;
    height: 1556.42px;
    left: -717.75px;
    top: -656.2px;

    background: #8bbef3;
    border-radius: 333px;
    transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  }
`

const TopBackground1 = styled.img`
  position: absolute;
  left: -14.63%;
  right: 9.75%;
  top: -40.95%;
  bottom: 78.14%;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.98, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground0 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -14.63%;
  right: 0.75%;
  top: -0.95%;
  bottom: 8.14%;

  opacity: 0.1;

  background: #8bbef3;
  border-radius: 333px;
  transform: matrix(0.9, -0.24, 0.2, 0.97, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const TopBackground2 = styled.img`
  overflow-x: hidden;
  position: absolute;
  left: -1.72%;
  right: 32.8%;
  top: -16.64%;
  bottom: 81.18%;

  background: #8bbef3;
  opacity: 0.05;
  border-radius: 333px;
  transform: matrix(1, -0.1, 0.08, 1, 0, 0);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
const Logo = styled.img`
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  margin: auto;
  max-width: 80%;
  margin: 200px 100px;

  @media only screen and (max-width: 625px) and (min-width: 100px) {
    margin: 10px 10px 100px 100px;
  }
`

const H1Text = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 35.8802px;
  line-height: 119%;
  /* or 43px */

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  font-feature-settings: "liga" off;

  margin: 30px auto;
  padding: auto;

  color: #ffffff;
  @media only screen and (max-width: 600px) and (min-width: 100px) {
  }
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const PText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16.7436px;
  line-height: 151%;
  /* or 25px */
  margin: 30px auto;
  color: #fafdff;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16.1461px 36.7772px;
  cursor: pointer;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 24px;

  color: #ffffff;

  background: #8578fc;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAForm = styled.form`
  margin: auto;
  flex-direction: row;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
`

const CTAInput = styled.input`
  display: flex;
  padding: 16.1461px 30.7772px;

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1461px;
  line-height: 24px;

  color: black;

  border: 0.897006px solid #8578fc;
  box-sizing: border-box;
  filter: drop-shadow(0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26));
  border-radius: 40.3653px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

/*
const Wave = styled.img`
  position: absolute;
  width: 100%;
  z-index: 0;
    top: 3139.21px;
`

const WaveTop = styled.img`
  position: absolute;
  width: 100%;
  opacity:80%;
  z-index: 0;
    top: 3139.21px;
`
*/

const FooterDiv = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  left: 0px;
  top: 0.21px;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const SignupDiv = styled.div`
  position: relative;

  width: 100%;
  height: 300px;
  z-index: 2;
  top: 55.21px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
